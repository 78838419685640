<script>
    import Typewriter from 'svelte-typewriter/src/Typewriter.svelte'
    import { MetaTags } from 'svelte-meta-tags';
</script>

<MetaTags
  title="Kula Workshop"
  description="Kula Workshop is the largest catalogue of Kula World mods, modding tools and modding info on the internet."
  openGraph={{
    url: 'https://www.kulaworkshop.net/',
    title: 'Kula Workshop',
    description: 'Kula Workshop is the largest catalogue of Kula World mods, modding tools and modding info on the internet.',
    site_name: 'Kula Workshop'
  }}
  twitter={{
    title: 'Kula Workshop',
    description: 'Kula Workshop is the largest catalogue of Kula World mods, modding tools and modding info on the internet.'
  }}
/>

<section>
    <h1 style="text-align: center">Welcome to Kula Workshop!</h1>

    <img src="/assets/images/welcome.png" alt="Kula Workshop Logo in the Sky">

    <p>
        Welcome to Kula Workshop, <br>the largest catalogue of 
        <Typewriter mode="loop">
            <span class="Game">Kula World</span>
            <span class="Game">Kula Quest</span>
            <span class="Game">Roll Away</span>
        </Typewriter>
        mods and knowledge on the internet!
        <br><br>
        Learn the history behind the games, the modding scene, how to create your own mods, and browse through our Mod Browser!</p>
</section>

<style>
    img {
        width: 85vw;
        margin: auto;
        display:block
    }

    h1 {
        font-weight: 700;
    }

    p {
        margin:auto;
        margin-top: 20px;
        text-align: center;
        display:block;
        width:90%;
        font-size: 20px;
    }

    @media only screen and (min-width: 1000px){
        img {
            width: 35vw;
            height: 19.77401129943503vw;
        }

        p {
            width:50%;
            font-weight: 600;
        }
    }

    .Game {
        background: linear-gradient(145deg, #ff849f, #da60ff);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
        font-size: 50px;
    }

    * {
        --cursor-color: #eee;
        --cursor-width: 0.1ch
    }
</style>