<script>
    import ToolCard from "../components/ToolCard.svelte";
    import { MetaTags } from 'svelte-meta-tags';
</script>

<MetaTags
  title="Tools - Kula Workshop"
  description="Browse tools used by us for modding all aspects of Kula World."
  openGraph={{
    url: 'https://www.kulaworkshop.net/tools',
    title: 'Tools - Kula Workshop',
    description: 'Browse tools used by us for modding all aspects of Kula World.',
    site_name: 'Kula Workshop'
  }}
  twitter={{
    title: 'Tools - Kula Workshop',
    description: 'Browse tools used by us for modding all aspects of Kula World.'
  }}
/>

<section>
    <h1>Kula World Tools</h1>
    <div class="Cards">
        <ToolCard 
            Name="Level Editor"
            Link="https://www.kulaworkshop.net/tools/leveleditor/beta/"
            Description="A full featured web-based level editor for Kula World."
            Icon="/assets/images/tools/leveleditor.png"
        />

        <ToolCard 
            Name="Randomizer"
            Link="https://github.com/SaturnKai/Randomizer"
            Description="A program for randomizing nearly every aspect of the game."
            Icon="/assets/images/tools/randomizer.png"
        />

        <ToolCard 
            Name="PAK Tool"
            Link="https://github.com/Kula-Workshop/KulaWorldPAKTool"
            Description="A program for extracting and creating Kula World .PAK files."
            Icon="/assets/images/tools/paktool.png"
        />
        </div>
        
    <h1>PSX Dev Tools</h1>
    <div class="Cards">
        <ToolCard 
            Name="QuickBMS"
            Link="http://aluigi.altervista.org/quickbms.htm"
            Description="Files extractor and reimporter, archives and file formats parser, advanced tool for reverse engineers and power users, and much more."
            Icon="http://aluigi.altervista.org/favicon.ico"
        />

        <ToolCard 
            Name="HxD"
            Link="https://mh-nexus.de/en/hxd/"
            Description="HxD is a carefully designed and fast hex editor which, additionally to raw disk editing and modifying of main memory (RAM), handles files of any size."
            Icon="/assets/images/tools/hxd.webp"
        />

        <ToolCard 
            Name="Hex Workshop"
            Link="http://www.hexworkshop.com/overview.html"
            Description="A set of hexadecimal development tools for Microsoft Windows, combining advanced binary editing with the ease and flexibility of a word processor."
            Icon="/assets/images/tools/hexworkshop.png"
        />

        <ToolCard 
            Name="Ghidra"
            Link="https://ghidra-sre.org/"
            Description="A software reverse engineering suite of tools developed by NSA's Research Directorate."
            Icon="/assets/images/tools/ghidra.png"
        />

        <ToolCard 
            Name="Ghidra PSX Loader"
            Link="https://github.com/lab313ru/ghidra_psx_ldr"
            Description="Sony Playstation PSX executables loader for Ghidra."
            Icon="https://avatars.githubusercontent.com/u/7189309?v=4"
        />

        <ToolCard 
            Name="PSX MODE2 EN"
            Link="https://www.romhacking.net/utilities/1417/"
            Description="Simple command-line tool to replace files in a PSX CD image."
            Icon="https://www.romhacking.net/community/images/1273image.jpg"
        />

        <ToolCard 
            Name="PlayStation VRAM Viewer"
            Link="https://www.romhacking.net/utilities/675/"
            Description="A program to decode, view and save the contents of the Video RAM generated by Playstation 1 emulators."
            Icon="/assets/images/tools/pvv.png"
        />

        <ToolCard 
            Name="jPSXdec"
            Link="https://github.com/m35/jpsxdec"
            Description="A modern, cross-platform PlayStation 1 audio/video converter."
            Icon="https://github.com/m35/jpsxdec/blob/readme/jpsxdec/src/jpsxdec/gui/icon48.png?raw=true"
        />

        <ToolCard 
            Name="PSound"
            Link="https://www.romhacking.net/utilities/679/"
            Description="An interesting and helpful utility which can play PSX sound files, and converts them into WAV files."
            Icon="http://snailrush.online.fr/favicon.ico"
        />

        <ToolCard 
            Name="MKPSXISO"
            Link="https://github.com/lameguy64/mkpsxiso"
            Description="Meant to provide a faster, cross-platform, modern replacement of the BUILDCD from the official development tools."
            Icon="https://avatars.githubusercontent.com/u/10251759?v=4"
        />
    </div>
        
    <h1>PSX Emulators</h1>
    <div class="Cards">
        <ToolCard 
            Name="no$psx"
            Link="http://problemkaputt.de/psx.htm"
            Description="Highly accurate emulation. Debugging functions include disassembler, breakpoints, assembler, VRAM viewer, polygon viewer, debug console window, and complete PSX hardware specs."
            Icon="/assets/images/tools/nocashpsx.webp"
        />
        
        <ToolCard 
            Name="Bizhawk"
            Link="https://github.com/TASEmulators/BizHawk"
            Description="A multi-system emulator written in C#. Debugging functions include RAM Watch, RAM Search, Lua Scripting and a HEX Memory Editor"
            Icon="https://files.tasvideos.org/bizhawk/corphawk_logo.jpg"
        />
        
        <ToolCard 
            Name="DuckStation"
            Link="https://github.com/stenzek/duckstation"
            Description="This is the best emulator visually, we use it for capturing mod gameplay and screenshots."
            Icon="/assets/images/tools/duck.webp"
        />
    </div>
    

</section>

<style>

    .Cards {
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(360px, 2fr));
        grid-gap: 1rem;
    }

    h1 {
        font-weight: 700;
        text-align: center
    }

    h1:not(:first-child) {
        border-top: 1px solid rgb(48, 48, 48);
        padding-top: 20px;
    }

</style>