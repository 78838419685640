<script>
    import { MetaTags } from 'svelte-meta-tags';
</script>

<MetaTags
  title="Contact - Kula Workshop"
  description="Contact us with any questions, comments, or suggestions."
  openGraph={{
    url: 'https://www.kulaworkshop.net/contact',
    title: 'Contact - Kula Workshop',
    description: 'Contact us with any questions, comments, or suggestions.',
    site_name: 'Kula Workshop'
  }}
  twitter={{
    title: 'Contact - Kula Workshop',
    description: 'Contact us with any questions, comments, or suggestions.'
  }}
/>

<section >
    <h1>Contact</h1>

    <div class="Form">
        <iframe title="Contact Google Form" id="Form" src="https://docs.google.com/forms/d/e/1FAIpQLSdavy0z_qKV3ok5esxtUATPJsxjm-iVncUP_8M5uztLLtTnwg/viewform?embedded=true" frameborder="0">Loading…</iframe>
    </div>
</section>

<style>
    h1 {
        font-weight: 700;
        text-align: center
    }

    iframe {
        width:100%;
        height: 100vh
    }

    iframe::-webkit-scrollbar {
        width: 15px;
    }

    iframe::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 1.5rem 0 0 1.5rem;
        margin: 10px;
    }

    iframe::-webkit-scrollbar-thumb {
        border-radius: 1.5rem 0 0 1.5rem;
        background-image: linear-gradient(145deg, #aa4376, #81209e);
        box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    }

    .Form {
        border-radius: 1.5rem;
        background: linear-gradient(145deg, #fafafa, #fafafa);
        box-shadow:  5px 5px 10px #111111,
                    -5px -5px 10px #171717;
        padding:30px 0 30px
    }
</style>