<script>
    export let Route = '';
    export let Logo = false;
    export let Entries;

</script>

<div class="Menu-Wrapper">
    {#if Logo}
        <img class="Logo" src="/assets/images/logo.png" alt="Kula Workshop Logo">
    {/if}
    <div class="Entries-Wrapper">
        {#each Entries as Entry}
            <a href={Entry.Link} class="Item {Route === Entry.Title ? 'Active' : ''}">{Entry.Title}</a>
        {/each}
    </div>
</div>

<style lang="scss">
    .Menu-Wrapper {
        display:flex;
        flex-direction: column;
    }

    .Entries-Wrapper {
        border-radius: 1.5rem;
        background: linear-gradient(145deg, #121212, #151515);
        box-shadow:  5px 5px 10px #111111,
                    -5px -5px 10px #171717;
        margin:20px 2.5vw;
        padding:10px;
        width:15vw;
        max-width: 180px;
        min-width: 140px;
        transition: .5s ease;
    }

    .Item {
        display:block;
        text-align: center;
        text-decoration: none;
        padding: 5px;
        color: #ccc;
        transform: scale(95%, 95%);
        transition: .25s ease;

        border-radius: 1.5rem;
        background: linear-gradient(145deg, #121212, #151515);
        box-shadow:  5px 5px 10px #111111,
                    -5px -5px 10px #171717;
        margin:1vw;
        width:15vw;
        max-width: 180px;
        min-width: 140px;
        border: 1px solid #a1618000;
        -webkit-text-size-adjust: 100%;
        will-change: transform;
    }

    .Item:hover {
        box-shadow:  10px 10px 15px #111111,
                    -10px -10px 15px #171717;
        transform: scale(100%, 100%);
        transition: .25s ease;
        -webkit-text-size-adjust: 100%;
        will-change: transform;
    }

    .Item.Active {
        background: linear-gradient(145deg, #aa4376, #81209e);
        font-weight: bold;
        border: 1px solid #6d2b4b;
        transition: .2s ease;
    }

    .Item:active {
        transform: scale(90%, 90%);
        transition: .2s ease;
        will-change: transform;
    }

    .Logo {
        width: calc(5vw + 60px);
        height: calc(5vw + 60px);
        margin: 0 auto;
        padding-left: 1vw;
    }

    @media only screen and (max-width: 1000px){
        .Entries-Wrapper {
            width: 80vw;
            max-width: 80vw;
        }

        .Item {
            margin:2.5vw;
            max-width: 100%;
            width: calc(100% - 5vw);
            padding: 2vw 0 2vw;
        }

        .Logo {
            padding:0
        }
    }

</style>