<script>
    // Importing Modules
    import page from 'page'

    //Importing Components
    import Menu from './components/Menu.svelte'
    import MainSection from './components/MainSection.svelte';

    // Importing Routes
    import Home from './routes/Home.svelte'
    import History from './routes/History.svelte'
    import Tools from './routes/Tools.svelte'
    import Tutorials from './routes/Tutorials.svelte'
    import Progress from './routes/Progress.svelte'
    import Downloads from './routes/Downloads.svelte'
    import About from './routes/About.svelte'
    import Contact from './routes/Contact.svelte'
    import NotFound from './routes/NotFound.svelte'

    //Declaring Routes
    let ActiveRoute = { Name: '404', Component: NotFound}

    page('/', () => ActiveRoute = { Name: 'Home', Component: Home })
    page('/home', () => ActiveRoute = { Name: 'Home', Component: Home })

    // page('/history', () => ActiveRoute = { Name: 'History', Component: History })

    page('/tutorials', () => ActiveRoute = { Name: 'Tutorials', Component: Tutorials })

    page('/tools', () => ActiveRoute = { Name: 'Tools', Component: Tools })

    page('/progress', () => ActiveRoute = { Name: 'Progress', Component: Progress })

    // page('/downloads', () => ActiveRoute = { Name: 'Downloads', Component: Downloads })

    // page('/about', () => ActiveRoute = { Name: 'About', Component: About })

    page('/contact', () => ActiveRoute = { Name: 'Contact', Component: Contact })

    // $: {
    //     document.title = ActiveRoute.Name + ' - Kula Workshop'
    // }

    page.start()

    //Declaring Menus
    let IndexEntries = [
        { Title: 'Home', Link: '/' },
        // { Title: 'History', Link: '/history' },
        { Title: 'Tools', Link: '/tools' },
        { Title: 'Mod Browser', Link: '/mods' },
        { Title: 'Tutorials', Link: '/tutorials' },
        { Title: 'Progress', Link: '/progress' },
        // { Title: 'Downloads', Link: '/downloads' },
        { Title: 'Github', Link: 'https://github.com/Kula-Workshop' },
        // { Title: 'About', Link: '/about' },
        { Title: 'Contact', Link: '/contact' },
    ]


</script>

<main>
    <Menu Route={ActiveRoute.Name} Entries={IndexEntries} Logo />
    <MainSection Page={ActiveRoute.Component} />
    <!-- <Menu Route={ActiveRoute.Name} /> -->
</main>

<style type='text/scss'>
    main {
        display:flex;
        justify-content: space-evenly;
        align-items: start;
        flex-wrap: wrap;
    }
</style>