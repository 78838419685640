<script>
    export let Percentage = 0
    export let Title = "Placeholder Title"
    export let Checklist = []
    export let Parent = false
    export let Background = 0
    let ColorClass = ''

    import {
        CheckmarkCircle,
        CloseCircle
    } from 'svelte-ionicons'

    function BarClass(Percentage) {
        if(Percentage > 33) return Percentage > 66 ? '' : 'Yellow'
        else return 'Red'
    }


    if(Parent) {
        let Total = 0
        let List = Checklist
        console.log(List)
        
        for(let li = 0; li < List.length; li++) {
            Total += List[li].length
            for(let i = 0; i < List[li].length; i++) {
                if(List[li][i][0]) Percentage += 1
            }
        }
        console.log(Percentage, Total)
        Percentage = Math.round((Percentage / Total) * 100)
        ColorClass = BarClass(Percentage)
    } else {
        let Total = Checklist.length
        for(let i = 0; i < Total; i++) {
            if(Checklist[i][0]) Percentage += 1
        }
        Percentage = Math.round((Percentage / Total) * 100)
        ColorClass = BarClass(Percentage)
    }
    

</script>

<div class="Wrapper {Parent ? 'Parent' : ''}" style="{Parent ? 'background: url("/assets/images/backgrounds/' + Background + '.webp") center;' : ''}">
    <div class="Info">
        <h1 class="Title">{Title}</h1>
        <h2 class="Percentage">{Percentage}%</h2>
    </div>
    <div class="Bar-Wrapper">
        <div class="Bar {ColorClass} {Percentage < 1 ? 'Hidden' : ''}" style="width:{Percentage}%"></div>
    </div>
    {#if Checklist.length > 0 && !Parent}
        <hr class="Divider">
        <ul class="Checklist">
            {#each Checklist as Check}
                <li>
                    {#if Check[0]}
                        <div class="Green">
                            <CheckmarkCircle  />
                        </div>
                    {:else}
                        <div class="Red">
                            <CloseCircle class="Red" />
                        </div>
                    {/if}
                    <span class="Check">{Check[1]}</span>
                </li>
            {/each}
        </ul>
    {/if}
</div>

<style lang="scss">
    .Wrapper {
        display:flex;
        flex-direction: column;
        align-items: center;
        transition:.2s ease-in-out;
        background: linear-gradient(145deg, #121212, #151515);
        box-shadow:  5px 5px 10px #111111,
                    -5px -5px 10px #171717;
        border-radius: 1.5rem;
        padding:20px;
        border: 1px solid #6b6b6b10;
        // width: 100%;
        // max-width: 450px;
    }

    .Wrapper:hover {
        transition:.3s ease-in-out;
        background: linear-gradient(145deg, #121212, #151515);
        box-shadow:  5px 5px 23px #0e0e0e,
                -5px -5px 23px #1a1a1a;
        border: 1px solid #52525241;
    }

    .Info {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-weight: 500;
    }

    h1, h2 {
        font-weight: 600;
        margin: 0
    }

    .Bar-Wrapper, .Bar {
        border-radius: 1rem;
    }

    .Bar-Wrapper {
        background-color: grey;
        height:30px;
        width: 100%;
        background: #1c1c1c;
        margin-top: 20px;
        border: 1px solid #a7a7a70c;
        box-shadow: inset 5px 5px 10px #181818,
                    inset -5px -5px 10px #202020;
    }

    .Bar-Wrapper > .Bar {
        background: linear-gradient(145deg, #259225, #207a20);
        border: 1px solid #a7a7a725;
        box-shadow: inset 5px 5px 10px #1e741e,
                    inset -5px -5px 10px #289c28;
        height:28px
    }

    .Bar.Red {
        background: linear-gradient(145deg, #c52626, #ea2d2d);
        box-shadow: inset 5px 5px 10px #ba2323,
                    inset -5px -5px 10px #fc2f2f;
    }

    .Bar.Yellow {
        background: linear-gradient(145deg, #eaae2c, #c59325);
        box-shadow: inset 5px 5px 10px #ba8b23,
                    inset -5px -5px 10px #fcbb2f;
    }

    .Divider {
        width: 100%;
        margin: 20px 0 20px;
        border: 1px solid #6b6b6b10;
    }

    .Checklist {
        display:grid;
        grid-template-columns: 100%;
        gap: .5rem;
        width: 100%;
        list-style: none;
        padding: 0
    }

    .Checklist .Green {
        fill:#259225;
        padding-right:5px
    }

    .Checklist .Red {
        fill:#db2a2a;
        padding-right:5px
    }

    .Checklist .Check {
        font-weight: 500;
    }

    .Checklist > li {
        display:flex;
        line-height: 24px;
        font-size: 19px;
    }

    .Hidden {
        display: none;
    }

    .Wrapper.Parent, .Wrapper.Parent:hover {
        background-size:contain auto;
    }

    .Wrapper.Parent:hover {
        filter:brightness(105%)
    }


</style>