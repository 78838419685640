<script>
    export let Icon = "/assets/images/key.gif";
    export let Link;
    export let Name;
    export let Description;
</script>

<a href="{Link}" class="Card" target="_blank">
    <div class="Head">
        <img class="Icon" src={Icon} alt="{Name} Icon">
        <h2 class="Name">{Name}</h2>
    </div>
    <p class="Description">{Description}</p>
</a>

<style lang="scss">
    .Card {
        transition:.1s ease-in-out;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        background: linear-gradient(145deg, #121212, #151515);
        box-shadow:  5px 5px 10px #111111,
                    -5px -5px 10px #171717;
        border-radius: 1.5rem;
        padding:10px 20px 10px 20px;
        border: 1px solid #6b6b6b10;
    }

    .Card:hover {
        transition:.1s ease-in-out;
        background: linear-gradient(145deg, #121212, #151515);
        box-shadow:  5px 5px 23px #0e0e0e,
                -5px -5px 23px #1a1a1a;
        border: 1px solid #52525241;
    }

    .Card:active {
        transition: .5s ease;
        background: linear-gradient(145deg, #151515, #171717);
        border: 1px solid #52525262;
    }

    .Card > .Head  {
        display: flex;
        align-items: center;
    }

    .Card > .Head > .Icon {
        height: 40px;
        width: 40px;
        border-radius: 10px;
    }

    .Card > .Head > .Name {
        margin-left: 20px;
    }

    .Card > .Description {
        margin-top:0px
    }

</style>