<script>
    import ToolCard from "../components/ToolCard.svelte";
    import { MetaTags } from 'svelte-meta-tags';
</script>

<MetaTags
  title="Tutorials - Kula Workshop"
  description="Learn how to mod Kula World, Kula Quest, or Roll Away."
  openGraph={{
    url: 'https://www.kulaworkshop.net/tutorials',
    title: 'Tutorials - Kula Workshop',
    description: 'Learn how to mod Kula World, Kula Quest, or Roll Away.',
    site_name: 'Kula Workshop'
  }}
  twitter={{
    title: 'Tutorials - Kula Workshop',
    description: 'Learn how to mod Kula World, Kula Quest, or Roll Away.'
  }}
/>

<section>
    <h1>Level Editor Tutorial</h1>

    <iframe src="https://www.youtube-nocookie.com/embed/3ylRfiFLmo4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <h3>Tools Used</h3>
    <div class="Cards">
        <ToolCard 
            Name="Level Editor"
            Link="https://www.kulaworkshop.net/tools/leveleditor/"
            Description="A full featured web-based level editor for Kula World."
            Icon="/assets/images/tools/leveleditor.png"
        />

        <ToolCard 
            Name="PAK Tool"
            Link="https://github.com/Kula-Workshop/KulaWorldPAKTool"
            Description="A program for extracting and creating Kula World .PAK files."
            Icon="/assets/images/tools/paktool.png"
        />

        <ToolCard 
            Name="PSX MODE2 EN"
            Link="https://www.romhacking.net/utilities/1417/"
            Description="Simple command-line tool to replace files in a PSX CD image."
            Icon="https://www.romhacking.net/community/images/1273image.jpg"
        />
    </div>

    <h1>Randomizer Tutorial</h1>

    <iframe src="https://www.youtube-nocookie.com/embed/ebwn7yB1bUc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <h3>Tools Used</h3>
    <div class="Cards">
        <ToolCard 
            Name="Randomizer"
            Link="https://github.com/SaturnKai/Randomizer"
            Description="A program for randomizing nearly every aspect of the game."
            Icon="/assets/images/tools/randomizer.png"
        />

        <ToolCard 
            Name="MKPSXISO"
            Link="https://github.com/lameguy64/mkpsxiso"
            Description="Meant to provide a faster, cross-platform, modern replacement of the BUILDCD from the official development tools."
            Icon="https://avatars.githubusercontent.com/u/10251759?v=4"
        />
    </div>

</section>

<style>
    iframe {
        width: 85vw;
        height: 48vw;
        margin: auto;
        display:block;
        border-radius: 1.5rem;
        border: 1px solid #6b6b6b10;
        box-shadow:  5px 5px 10px #111111,
                    -5px -5px 10px #171717;
        background: linear-gradient(
            100deg,
            rgba(255, 255, 255, 0) 40%,
            #441933 50%,
            #370e3f 55%,
            rgba(255, 255, 255, 0) 60%
        ) #121212;
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 5s glare ease-in-out infinite;
        padding:10px
    }

    @keyframes glare {
        to {
            background-position-x: -20%;
        }
    }

    .Cards {
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(360px, 2fr));
        grid-gap: 1rem;
        width: 100%;
        margin: auto
    }

    h1 {
        font-weight: 700;
        text-align: center
    }

    h3 {
        text-align: center;
        font-weight: 600;
    }

    h1:not(:first-child) {
        border-top: 1px solid rgb(48, 48, 48);
        padding-top: 20px;
    }

    @media only screen and (min-width: 1000px){
        iframe {
            width: 40vw;
            height: 23vw;
        }

        .Cards {
            width: 40vw;
        }

    }
</style>