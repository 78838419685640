<script>
    import ProgressBar from "../components/ProgressBar.svelte";
    import { MetaTags } from 'svelte-meta-tags';
    let LevelEditor = [
        [ // PAK
            [true, "Reading PAK file contents"],
            [true, "Extracting files from PAK files"],
            [true, "Importing files into PAK files"],
            [true, "Creating PAK files from scratch"]
        ],
        [ // Level
            [true, "Creating / removing blocks"],
            [true, "Creating / removing items"],
            [true, "Changing item properties"],
            [true, "Creating working buttons"],
            [true, "Creating working transporters"],
            [true, "Creating lasers"],
            [true, "Creating moving blocks"],
            [true, "Changing default time limit"],
            [true, "Reversing invisible blocks"],
            [true, "Creating hidden levels"],
            [true, "Creating bonus levels"],
        ],
        [ // Release
            [true, "Adding / deleting / moving blocks"],
            [true, "Editing block types and items"],
            [true, "Exporting Level files"],
            [true, "Exporting Level files"],
            [true, "Working transporters"],
            [true, "Working buttons"],
            [false, "Version 2 user interface"],
            [false, "Version 2 backend"]
        ]
    ]

    let TextureConverter = [
        [ // TGI
            [true, "Locate texture palettes"],
            [true, "Locate small / medium / large textures"],
            [true, "Locate skybox palettes"],
            [true, "Locate skybox"],
            [true, "Editing / replacing textures"],
            [true, "Editing / replacing skybox"],
            [false, "Editing lense flare position"],
            [false, "Editing model lighting"]
        ],
        [ // Release
            [true, "Automate texture replacing"],
            [true, "Automate skybox replacing"],
            [false, "Automated color limiting"],
            [false, "User interface"],
        ]
    ]

    let ModelConverter = [
        [ // GGI
            [true, "Locate model index"],
            [true, "Locate model geometries"],
            [true, "Locate model colors"],
            [true, "Plot vertices on the X and Y axis"],
            [false, "Fully plot verticies on all axes"],
            [false, "Full control over vertex positions"],
            [false, "Full control over model colors"]
        ],
        [ // Release
            [false, "User interface"],
            [false, "Automated model viewer"],
            [false, "Automated model conversion"]
        ]
    ]

</script>

<MetaTags
  title="Progress - Kula Workshop"
  description="View progress on Kula World modding tools from Kula Workshop."
  openGraph={{
    url: 'https://www.kulaworkshop.net/progress',
    title: 'Progress - Kula Workshop',
    description: 'View progress on Kula World modding tools from Kula Workshop.',
    site_name: 'Kula Workshop'
  }}
  twitter={{
    title: 'Progress - Kula Workshop',
    description: 'View progress on Kula World modding tools from Kula Workshop.'
  }}
/>

<section>
    <h1 style="font-size: 2rem; margin-bottom:0px">Progress</h1>
    <p style="font-size: 1.2rem; text-align:center; margin:auto; width:80%; line-height:1.5rem">
        Welcome to the progress page! 
        Our goal is to provide public tools for modifying various aspects of the games, mainly creating levels, world themes / textures, and models.
        The end result for each program below is to be an easy to learn and powerful tool, as well as being as lightweight and accessable as possible.
    </p>
    
    <h1>Creating / Editing Levels</h1>
    <ProgressBar Title="Level Editor" Parent Checklist={LevelEditor} Background=5 />
    <div class="Bars">
        <ProgressBar Title="PAK Files" Checklist={LevelEditor[0]} />
        <ProgressBar Title="Level Files" Checklist={LevelEditor[1]}  />
        <ProgressBar Title="Public Release" Checklist={LevelEditor[2]}  />
    </div>

    <h1>Creating / Editing Worlds</h1>
    <ProgressBar Title="Texture Converter" Parent Checklist={TextureConverter} Background=4 />
    <div class="Bars">
        <ProgressBar Title="TGI Files" Checklist={TextureConverter[0]} />
        <ProgressBar Title="Public Release" Checklist={TextureConverter[1]}  />
    </div>

    <h1>Creating / Editing Models</h1>
    <ProgressBar Title="Model Converter" Parent Checklist={ModelConverter} Background=3 />
    <div class="Bars">
        <ProgressBar Title="GGI Files" Checklist={ModelConverter[0]} />
        <ProgressBar Title="Public Release" Checklist={ModelConverter[1]}  />
    </div>
</section>

<style>
    .Bars {
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(360px, 3fr));
        gap: 1rem;
        width:100%;
        margin-top: 20px;
    }

    h1 {
        font-weight: 700;
        text-align: center
    }

    h1:not(:first-child) {
        border-top: 1px solid rgb(48, 48, 48);
        padding-top: 20px;
    }
    
</style>