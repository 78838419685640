<section>
    <h1>404</h1>
    <img src="/assets/images/key.gif" alt="Kula World Key">
    <p>The page you were looking for does not exist.</p>
</section>

<style>
    img {
        width: 100px;
        margin: auto;
        display:block;
        animation: floating 2s ease-in-out infinite;
    }

    h1 {
        font-weight: 700;
        font-size: 40px;
        text-align: center;
    }

    p {
        margin:auto;
        margin-top: 20px;
        text-align: center;
        display:block;
        width:90%;
        font-size: 20px;
    }

    @media only screen and (min-width: 1000px){

        p {
            width:60%
        }
    }

    section {
        padding:100px 0 100px;
    }

    @keyframes floating {
        0% {
            transform: translateY(0px)
        }
        50% {
            transform: translateY(-20px)
        }
        100% {
            transform: translateY(0px)
        }
    }
</style>