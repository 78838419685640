<script>
    export let Page;
</script>

<main>
    <svelte:component this={ Page } />
</main>

<style lang="scss">
    main {
        width: 100%;
        padding:20px;
        margin-bottom:40px
    }

    @media only screen and (min-width: 1000px){
        main {
            width: 60%;
        }
    }

    @media only screen and (min-width: 1600px){
        main {
            width: 70%;
        }
    }
        
</style>